<template>
  <UiBaseModal
    v-model="isVisible"
    persistent
    backdrop="none"
    :prevent-scroll="false"
    :classes="{
      panelWrapper: 'items-end justify-end',
      panel: 'p-xs md:m-xs max-w-sm md:rounded-md',
    }"
  >
    <div class="bg-muted p-s grid place-content-center rounded-md">
      <NuxtImg :src="getImageUrl('icons/cookie.webp')" loading="eager" style="height: 80px" />
    </div>

    <strong class="mb-xxxs mt-xxs block font-semibold" v-text="$t('cookies.title')" />

    <i18n-t
      class="text-muted-foreground prose-strong:font-semibold whitespace-pre-line text-base font-light"
      keypath="cookies.rich_text"
      tag="p"
    >
      <template #policy_link>
        <UiLink class="font-semibold underline underline-offset-4" :to="{ name: 'privacy' }">
          {{ $t("cookies.cookie_policy") }}
        </UiLink>
      </template>
    </i18n-t>

    <div class="mt-s flex flex-col gap-2">
      <UiButton @click="accept">{{ $t("cookies.accept") }}</UiButton>
      <UiButton @click="decline" variant="outline">{{ $t("common.decline") }}</UiButton>
    </div>
  </UiBaseModal>
</template>

<script setup lang="ts">
const isVisible = ref(false)
const gtm = useGtm()
const cfg = useAppConfig()

const accept = () => {
  gtm.enableCookies()
  isVisible.value = false
}

const decline = () => {
  gtm.disableCookies()
  isVisible.value = false
}

onMounted(() => {
  if (!cfg.analytics?.enabled) {
    return
  } else if (gtm.isFirstTime.value) {
    isVisible.value = true
  }
})
</script>
